@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.calendarContainer {
  align-items: stretch;
  height: auto;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    min-height: auto;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: 24px;

  align-self: stretch;
}
