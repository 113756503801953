@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.occupancyRateContainer {
  padding: 30px;
  width: 416px;
  height: 400px;
  background: $white;
  border-radius: 24px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
}

.occupancyRateHeader {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.occupancyRate {
  margin: 35px 30px 0 30px;
  height: 94px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  p {
    color: $light-blue;
    margin-bottom: 12px;
  }
}

.dropdown {
  @media only screen and (max-width: 768px) {
    width: 130px;
    height: 32px;
  }
}
