@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.settingsContainer {
  padding: 32px;
  width: 856px;
  height: auto;
  background: $white;
  border-radius: 24px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 32px 16px;
  }
}

.contactForm {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    gap: 16px;
  }
}

.formControl {
  width: 384px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.input {
  @media only screen and (max-width: 768px) {
    height: 52px;
  }
}

.label {
  font-weight: 400;
  font-size: 16px;
  color: $light-blue;
  margin: 0 0 16px 0;
}

.actionFormControl {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.toast {
  position: absolute;
  top: 7%;
}

.loader {
  color: $white;
}