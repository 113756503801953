@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.reservationsHighlightsWrapper {
  padding: 32px;
  width: 100%;
  max-width: 1296px;
  height: 238px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  background: $white;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 16px;
    gap: 24px;
  }
}

.reservationsHighlightsHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.highlights {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;


  @media only screen and (max-width: 768px) {
    margin-top: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.highlight {
  @media only screen and (max-width: 768px) {
    //margin-left: 40px;
    gap: 8px;

    h2 {
      font-size: 24px;
    }
  }
}

.dropdown{
  @media only screen and (max-width: 768px) {
    width: 130px;
    height: 32px;
  }
}