@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.calendarContainer {
  padding: 32px;
  background: $white;
  border-radius: 24px;
  width: 967px;
  min-height: 864px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    min-height: auto;
    width: 100%;
    padding: 16px;
    padding-top: 32px;
  }
}