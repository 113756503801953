@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.cards {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 636px;
  flex: 1;
}

.block {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 24px;
}

@media only screen and (max-width: 1200px) {
  .cards {
    flex-direction: row;
    max-width: 100%;
    align-items: center;
  }

  .block {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 992px) {
  .cards {
    flex-direction: column;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .block {
    width: auto;
    flex-direction: column;
    align-items: center;
  }
}
