@import '../../../../../../../../assets/styles/variables';
@import '../../../../../../../../assets/styles/base';
.input {
  width: 330px;
  height: 40px;
  color: $blue-grey;
  opacity: 100%;
  padding-right: 0;
  overflow: hidden;
  cursor: pointer;

  input {
    cursor: pointer;

    &::placeholder {
      color: $blue-grey;
      opacity: 100%;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 768px) {
        width: 250px;
        height: 32px;

        font-size: 12px;
      }
    }

    @media only screen and (max-width: 440px) {
      padding-left: 12px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 250px;
    height: 32px;
  }

  @media only screen and (max-width: 440px) {
    width: 180px;
    font-size: 9px;
  }

  .notchedOutline {
    border: 2px solid $ghost-white;
  }

  &:hover {
    .notchedOutline {
      border-color: $blue-grey
    }
  }
}

.outlined {
  border: 1px solid $ghost-white;
}

.adornedEnd {
  height: 40px;
  padding: 8px 24px;
  background: $ghost-white;

  @media only screen and (max-width: 768px) {
    padding: 8px 12px;
  }

  @media only screen and (max-width: 400px) {
    padding: 8px 8px;
  }
}
