@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/base';
.tableContainer {
  padding: 32px;
  width: 100%;
  height: auto;
  background: $white;
  border-radius: 24px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 32px 16px 16px 16px;
  }

  @media only screen and (max-width: 400px) {
    padding: 32px 8px 16px 8px;
  }
}

.header,
.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.header {
  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 400px) {
    column-gap: 0;
  }
}

.navigation {
  display: flex;
}

.navLink {
  margin-right: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
  text-decoration: none;

  @media only screen and (max-width: 400px) {
    column-gap: 8px;
    margin-right: 8px;
  }
}

.reservationsTable {
  width: 100%;
}

.tableHead {
  background: $ghost-white;

  & th {
    font-size: 16px;
    font-weight: 400;
    color: $light-blue;
    border: 0;
    padding: 8px;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
      padding: 6px;
    }

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.tableBody {
  & tr:first-of-type {
    & td {
      padding-top: 20px;
    }
  }
}

.tableRow {
  @media only screen and (max-width: 768px) {
    padding-bottom: 16px;
  }

  & td {
    font-size: 16px;
    color: $dark-grey;
    padding: 10px;
    border: 0;

    &.blueBold {
      color: $light-blue;
    }

    &.greenBold {
      color: $green;
    }

    &.outerDate {
      font-weight: 500;
      font-size: 18px;
      @media only screen and (max-width: 768px) {
        padding-bottom: 24px;
      }
    }

    .innerDateBlock {
      width: 159px;
      @media only screen and (max-width: 768px) {
        width: 75px;
        padding-left: 8px;
      }
    }

    &.total {
      @media only screen and (max-width: 768px) {
        padding-top: 24px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    @media only screen and (max-width: 768px) {
      padding: 0 0 16px 0;
    }

    p {
      &.blueBold {
        color: $light-blue;
      }

      &.greenBold {
        color: $green;
      }

      @media only screen and (max-width: 768px) {
        margin-top: 0;
      }

      &.dates {
        @media only screen and (max-width: 768px) {
          color: $light-blue;
          font-size: 14px;
        }
      }

      &.shortMonthName {
        font-weight: 500;
        font-size: 18px;
        color: $light-blue;
        margin-top: 0;

        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      &.nights {
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      &.name {
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      &.guests {
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      &.netIncome {
        @media only screen and (max-width: 768px) {
          font-weight: 500;
        }
      }
    }
  }
}

.paid {
  border: 2px solid $green;
  border-radius: 10px;
}

.owner {
  border: 2px solid $light-blue;
  border-radius: 10px;
}
