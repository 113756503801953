@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/base';
.react-datepicker {
  font-family: 'Poppins', sans-serif;
  border: 1px solid $ghost-white;
  border-radius: 16px;
  box-sizing: border-box;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  &__header {
    background: $white;
    border: 0;
    padding: 0;
  }

  &__day-names {
    margin-top: 8px;
    margin-bottom: 0;
  }

  &__day-name, &__day {
    font-size: 12px;
    line-height: 26px;
  }

  &__day-name {
    color: $blue-grey;
    width: 30px;
    margin: 0;
  }

  &__month-container {
    padding: 16px;
  }

  &__month {
    margin: 0;
  }

  &__week {
    display: flex;
    align-items: center;
    width: 210px;
    height: 30px;
    margin-bottom: 4px;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    // Override react-datepicker__day styles
    & > .react-datepicker__day {
      color: $spanish-grey;
      margin: 0;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.react-datepicker__day--in-selecting-range {
        &:not(.react-datepicker__day--selecting-range-start) {
          &:hover {
            border-radius: 8px;
            background: $ghost-white;
          }
        }
      }

      &--in-range {
        border-radius: 0;
        background: $ghost-white;
      }

      &--in-selecting-range {
        border-radius: 0;
        background: $white;
      }

      &--keyboard-selected {
        border-radius: 0;
        background: $white;
      }

      &--range-start,
      &--range-end,
      &--selecting-range-start {
        background: $blue-grey;
        color: $white;
        border-radius: 8px;
        font-weight: bold;

        &.react-datepicker__day--today {
          @extend .react-datepicker__day--range-start;
        }
      }

      &--outside-month {
        visibility: hidden;
      }
    }
  }
}

.datePickerHeader {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.datePickerDay, .datePickerWeekDay {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  color: $spanish-grey;
}

.datePickerWeekDay {
  width: 30px;
  height: 26px;
  line-height: 26px;
}
