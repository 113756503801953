@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.mobileHeaderContainer {
  border-bottom: 1px solid $ghost-white;
  padding: 8px 16px 8px 28px;
  width: 100%;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
}

.logo {
  max-width: 143px;
  max-height: 38px;
}

.mobileMenuContainer {
  border-right: 1px solid $ghost-white;
  width: 237px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 79px;
  left: 0;
  visibility: visible;
  background: $white;
  z-index: 3;
}

.hidden {
  display: none;
  visibility: hidden;
}

.userData {
  margin: 30px 0 30px 25px;
}

.userName {
  color: $light-blue;
}

.userListing {
  color: $grey;
}
