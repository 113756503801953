@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.legendContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
}

.legend {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.legendLabel {
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
}

.line {
  width: 28px;
  height: 5px;
  border-radius: 25%;
}
