@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/base';
.cardContainer {
  padding: 32px;
  height: 242px;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 24px;
  gap: 30px;
  //overflow: hidden;
}

.cardHeader,
.cardBody {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  h4 {
    @media only screen and (max-width: 768px) {
      width: 122px;
    }
  }
}

.cardBody {
  margin-top: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .cardContainer {
    width: 100%;
    max-width: 636px;
    height: 212px;
    row-gap: 15px;
  }

  .cardBody {
    margin-top: 0;
  }

  .highlight {
    h3 {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .cardContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .cardContainer {
    width: 100%;
    height: auto;
    padding: 16px;
  }

  .highlight {
    gap: 8px;

    h2 {
      font-size: 24px;
    }
  }

  .dropdown {
    width: 130px;
    height: 32px;
  }
}

@media only screen and (max-width: 400px) {
  .highlight {
    padding-left: 20px;
  }
}
