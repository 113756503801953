@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.revenuePieContainer {
  padding: 30px;
  max-width: 636px;
  width: 100%;
  height: 362px;
  background: $white;
  border-radius: 24px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 16px;
  }
}

.revenuePieHeader {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4 {
    @media only screen and (max-width: 768px) {
      width: 167px;
      padding-bottom: 24px;
      align-items: flex-start;
    }
  }
}

.legend {
  flex-direction: column;
}

.legendBlock {
  display: flex;
  gap: 16px;
}

.legendRow {
  justify-content: flex-start;
}

.pieContainer {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
}

.dropdown {
  width: 160px;

  @media only screen and (max-width: 768px) {
    width: 130px;
    height: 32px;
  }
}

@media only screen and (max-width: 1200px)  {
  .revenuePieContainer {
    max-width: 100%;
  }

  .pieContainer {
    display: flex;
  }
}
