@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.outlinedSelect {
  width: 170px;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    width: 130px;
    font-size: 12px;
    padding-left: 16px;
    height: 32px;
  }
}

.standardSelect {
  @media only screen and (max-width: 768px) {
    height: 32px;
    width: 78px;
  }
}

.selectMenu {
  text-align: center;
  color: $grey;
  box-shadow: none;
}