@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.upcomingReservContainer {
  padding: 32px 16px 32px 32px;
  width: 306px;
  height: 442px;
  background: $white;
  border-radius: 24px;
  flex: 1 1 auto;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
}

.upcomingReservHeader {
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;

  h4 {
    line-height: 30px;
  }
}

.link {
  color: $light-blue;
  text-decoration: none;
  margin-right: 16px;
}

.reservations {
  height: 85%;
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    height: auto;
  }
}

.reservation {
  margin-top: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: 62px;

  & svg {
    & path {
      fill: #D9D9D9;
    }
  }
}

.reservationContent {
  display: flex;
  gap: 12px;

  .date {
    min-width: 40px;
    display: flex;
    align-items: center;

    &__innerWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
      color: $dark-blue;
      text-align: center;
    }
  }

  .ownerBlocks {
    margin-left: 4px;
    line-height: 24px;

    p:first-child {
      font-size: 16px;
      line-height: 24px;
    }
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
  }
}

.paid {
  border: 2px solid $green;
  border-radius: 10px;
}

.owner {
  border: 2px solid $light-blue;
  border-radius: 10px;
}

.blue {
  color: $light-blue;
}

.green {
  color: $green;
}
