@import '../../../../../../../../assets/styles/variables';
@import '../../../../../../../../assets/styles/base';
.datePickerHeader {
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__month {
    margin-right: 16px;
    color: $blue-grey;
    font-size: 16px;
  }
}

.chevronLeft, .chevronRight {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $ghost-white;
  border-radius: 8px;
  cursor: pointer;
}

.chevronLeft {
  justify-self: flex-start;
}

.chevronRight {
  justify-self: flex-end;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}
