@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/base';
.wrapper {
  position: relative;
}

.popper {
  z-index: 1000;
  display: flex;
  position: absolute;
  right: 0;
  margin-top: 18px;
  animation: fadeIn 0.2s;

  & > div {
    display: flex;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}

.firstCalendar {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.secondCalendar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
