@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.contactInfoContainer {
  width: 464px;
  padding: 56px;
  border-right: 1px solid $ghost-white;


  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
    padding-top: 0;
  }
}

.contactsList {
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
  }
}

.contact {
  margin-top: 24px;
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: $dark-grey;
    margin: 0;
    display: flex;
    align-items: center;
  }
}

.label {
  font-weight: 400;
  font-size: 16px;
  color: $light-blue;
  margin: 0 0 16px 0;
}

.contactIcon {
  margin-right: 10px;
}

.socialLinks {
  margin-top: 35px;
  display: flex;
  align-items: center;
  gap: 36px;
}