@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/base';
.perfomanceHighlightsWrapper {
  max-width: 1292px;
  width: 100%;
  height: 238px;
  padding: 32px;
  background: $white;
  border-radius: 24px;
}

.highlightsHeader {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.highlights {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  min-height: 108px;
}

@media only screen and (max-width: 768px) {
  .perfomanceHighlightsWrapper {
    padding: 16px;
    padding-top: 32px;
    height: auto;
  }
  .CircleOccupancyHighlight_hightlightWrapper__AwNBH {
    flex-direction: column;
    padding-left: 0;
}
  .highlights {
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  .highlight {
    gap: 8px;

    h2 {
      font-size: 24px;
    }
  }

  .outlinedDropdown {
    height: 32px;
  }
}

@media only screen and (max-width: 1200px) {
  .perfomanceHighlightsWrapper {
    height: auto;
  }

  .highlights {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .highlight {
    flex: 1;
  }
}
