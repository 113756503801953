@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/base';
.calendarHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.headerControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  svg {
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    gap: 0;
  }
}

.date {
  font-size: 28px;
  font-weight: 500;
  color: $dark-blue;
  text-align: center;
  margin: 0 20px;
  width: 140px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
    width: 110px;
  }
}

.smallDate {
  font-size: 18px;
  font-weight: 500;
  color: $dark-blue;
  text-align: left;
  width: 150px;
}

.linkToYear {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 14px;

  h4 {
    color: $dark-grey;
  }


  & svg {
    & path {
      fill: $dark-grey;
    }
  }
}

.standardSelect {
  background-color: $white;
  color: $dark-blue;
  font-weight: 500;
  font-size: 16px;
  padding-right: 0;
}

.icon {
  padding-left: 0;
}

.selectMenu {
  background-color: $white;
  border: 1px solid $ghost-white;
  border-radius: 8px;
}

.menuItem {
  color: #B1B3C6;
}

.dropdown {
  background-color: $white;
  width: 80px;
  padding-right: 0;
}

.mobileYearHeader {
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
  }
}
