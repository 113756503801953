@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.container, .mobileContainer {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .mobileContainer {
    background: $white;
  }
  
  .logo {
    height: 28px;
  }
  
  .toast {
    position: absolute;
    top: 8%;
  }