@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.wrapper {
  flex: 1;
  max-width: 636px;
  width: 100%;
}

.revenueBarContainer {
  padding: 32px;
  width: 100%;
  height: 478px;
  background: $white;
  border-radius: 24px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
}

.revenueBarHeader {
  margin: 0 0 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 768px) {
    margin: 0 0 24px 0;
    justify-content: space-between;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1340px) {
    margin: 0 0 18px 0;
  }
}

.revenueBarHeaderLeft {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
}

.periodBlock {
  display: flex;
  gap: 12px;
  @media only screen and (max-width: 768px) {
    gap: 6px;
  }
}

.centerSelfAlign {
  align-self: center;
}


.switcher {
  display: flex;
  gap: 10px;
  @media only screen and (max-width: 768px) {
    align-self: flex-end;
    position: absolute;
    top: -5px;
  }
}

.button {
  border-radius: 8px;
}

.activeButton {
  background: $ghost-white;

  & svg path {
    fill: $dark-blue;
  }
}

.legendRow {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    max-width: 100%;
  }
}
