body {
  line-height: 24px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5FC;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

.recharts-layer.recharts-cartesian-axis-tick text {
  fill: #9798A5;
}

.react-datepicker__tab-loop__start, .react-datepicker__tab-loop__end {
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 1400px){
  body .ListingsPage_listingsTableContainer__-Cno- {
    width: 100%;
  }
  body .DocumentsPage_documentsTableContainer__9h4Bn {
    width: 100%;
}
  body .DocumentsPage_documentsWrapper__on5KQ > div {
  width: 100%;
}
}

@media only screen and (max-width: 768px) {
          .CircleOccupancyHighlight_hightlightWrapper__AwNBH {
            flex-direction: column!important;
          }
          .CircleOccupancyHighlight_hightlightWrapper__AwNBH .MuiBox-root.css-rmz4vk {
            order: 1;
        }
        .CircleOccupancyHighlight_hightlightWrapper__AwNBH h3 {
          order: 2;
          margin: 0;
          color: #424581;
          font-size: 16px;
          font-weight: 400;
        }
        .CircleOccupancyHighlight_hightlightWrapper__AwNBH .BasicHighlight_highlight__uAWSB {
          order: 3;
          padding-left: 0;
        }
        .CircleOccupancyHighlight_hightlightWrapper__AwNBH {
          gap: 8px;
        }

        body .DocumentsPage_documentsTableContainer__9h4Bn {
          width: 100%;
          padding-bottom: 0;
          margin-bottom: 0;
      }
      
     body .DocumentsPage_documentsTableContainer__9h4Bn {
          width: 100%;
      }
      body .DocumentsPage_tableHead__ls503 th {
        font-size: 14px;
        font-weight: 400;
    }
    body .DocumentsPage_tableRow__PDH8A td {
      font-size: 12px;
    }
    body .DocumentsPage_downloadAction__wiXAj {
      font-size: 12px;
    }
    .DocumentsPage_tableBody__fXper tr td:nth-of-type(1) {
      padding-left: 0;
  }

  body .DocumentsPage_tableBody__fXper tr td p {
    font-size: 12px;
    margin: 0;
}

  }
  @media only screen and (max-width: 575px){
    body .DocumentsPage_tableHead__ls503 th {
      font-size: 13px;
      padding: 8px;
      text-align: center;
  }
  }
