@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/base';
.calendarYear {
  margin-top: 32px;
  max-width: 1100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  //gap: 20px;

  @media only screen and (max-width: 768px) {
    margin-top: 24px;
  }
}

.month {
  flex-direction: column;

  .monthName {
    font-size: 18px;
    margin-top: 5px;
    color: $light-blue;
    cursor: pointer;

    &.current {
      font-weight: 500;
    }
  }
}
