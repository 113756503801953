@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.occupancyRateContainer {
  padding: 30px;
  width: 416px;
  height: 432px;
  background: $white;
  border-radius: 24px;
  @media only screen and (max-width: 768px) {
    padding: 16px;
    padding-top: 32px;
    width: 100%;
  }
}

.occupancyRateHeader {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerWithTooltip {
  display: flex;
  gap: 8px;
  align-items: center;
}

.legend {
  padding: 0 10px;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    padding-top: 6px;
    justify-content: center;
  }
}

.pieContainer {
  width: 100%;
  height: 75%;
  position: relative;

  //@media only screen and (max-width: 768px) {
  //  margin-bottom: 24px;
  //}
}

.total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dropdown {
  width: 160px;
  @media only screen and (max-width: 768px) {
    width: 130px;
    height: 32px;
  }
}

.toast {
  position: absolute;
  top: 38%;
  left: 20%;
}