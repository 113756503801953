@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.header {
  margin-bottom: 20px;
}

.tableContainer {
  padding: 32px;
  width: 100%;
  max-width: 636px;
  height: 362px;
  background: $white;
  border-radius: 24px;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
}

.tableWrapper {
  overflow: auto;

  @media only screen and (max-width: 768px) {
    overflow: visible;
  }
}

.table {
  width: 100%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.tableHead {
  background: $ghost-white;
  position: sticky;
  top: 0;

  & th {
    font-size: 16px;
    color: $light-blue;
    border: 0;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.tableBody {
  & tr:first-of-type {
    & td {
      padding-top: 20px;
    }
  }
}

.tableRow {
  & td {
    font-size: 16px;
    color: $light-blue;
    padding: 10px;
    border: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .tableContainer {
    max-width: 100%;
  }

  .table {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .tableHead {
    & th {
      padding: 6px 6px;
    }
  }
}
