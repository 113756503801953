@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.contactForm {
  width: 464px;
  padding: 56px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    padding: 16px;
    width: 100%;
  }
}

.nameInputs {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: $light-blue;
  margin: 0 0 16px 0;
}

.input {
  max-width: 160px;

  @media only screen and (max-width: 768px) {
    max-width: none;
  }
}

.action {
  //margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}