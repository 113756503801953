@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.highlight {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: auto;
  @media only screen and (max-width: 768px) {
    padding-left: 40px;
  }
}

.highlightValueContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.highlightValue {
  font-size: 28px;
  font-weight: 500;
  color: $light-blue;
  margin: 0;
}

.dynamicIndicator {
  margin-left: 6px;
}

.highlightLabelWithTooltip {
  display: flex;
  align-items: center;
}

.highlightLabel {
  font-weight: 400;
  margin: 0;
  width: auto;
  max-width: 220px;
  @media only screen and (max-width: 768px) {
    max-width: none;
  }
}

.green {
  color: $green;
}
