@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.documentsWrapper {
  align-items: center;
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.documentsTableContainer {
  padding: 32px;
  width: 1296px;
  min-height: 200px;
  height: auto;
  background: $white;
  border-radius: 24px;
  overflow-y: auto;
  margin-bottom: 30px;

  h4 {
    margin-bottom: 24px;
  }
}

.tableHead {
  background: $ghost-white;

  & th {
    font-size: 16px;
    color: $light-blue;
    border: 0;
    padding: 8px 16px;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.tableBody {
  & tr {
    border-bottom: 1px solid $ghost-white;

    & td {
      padding: 32px 0 24px 16px;
    }
  }

  & tr:last-of-type {
    border: none;
  }
}

.tableRow {
  & td {
    font-size: 16px;
    color: $dark-grey;
    padding: 10px;
    border: 0;
  }
}

.fileTypeBadge {
  font-weight: 500;
  text-transform: uppercase;
  color: $light-blue;
  padding: 8px 6px;
  margin-right: 16px;
  background: #F5F5FC;
  border-radius: 8px;
}

.fileName {
  color: $light-blue;
}

.downloadAction {
  font-size: 16px;
  font-weight: 400;
  color: $light-blue;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 16px;
}



@media screen and (max-width:768px) {
  .DocumentsPage_documentsWrapper__on5KQ > div {
    width: 100%;
}

.DocumentsPage_documentsTableContainer__9h4Bn {
    width: 100%;
}
}