@import '../../../../../../../assets/styles/variables';
@import '../../../../../../../assets/styles/base';
.monthBody {
  margin-top: 30px;
  width: 900px;
  border-left: 1px solid $ghost-white;
  overflow: hidden;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    border-left: none;
    margin-bottom: 16px;
  }
}

.dayWeekSection {
  display: flex;
  flex-direction: row;
  border-top: 1px solid $ghost-white;
  border-bottom: 1px solid $ghost-white;

  @media only screen and (max-width: 768px) {
    border-top: none;
  }
}

.dayWeek {
  color: $dark-grey;
  text-align: center;
  padding-top: 10px;
  width: 129px;
  height: 50px;
  border-right: 1px solid $ghost-white;

  @media only screen and (max-width: 768px) {
    color: #5d6293;
    border-right: none;
  }
}

.month {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    border-left: 1px solid $ghost-white;
  }
}

.weekSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $ghost-white;
}

.day {
  width: 129px;
  height: 125px;
  border-right: 1px solid $ghost-white;

  p {
    padding-left: 10px;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 768px) {
      font-size: 10px;
      margin-top: 0;
      padding-left: 6px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 80px;

    .todayDate {
      padding-left: 8px;
    }
  }
}

.todayDate {
  &:after {
    position: absolute;
    content: "";
    top: -3px;
    left: 4px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $ghost-white;
    z-index: -1;
    @media only screen and (max-width: 768px) {
      top: 1px;
      left: 3px;
      width: 22px;
      height: 22px;
    }
  }
}

.todayDateSmall {
  &:after {
    position: absolute;
    content: "";
    top: -4px;
    left: -1px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $ghost-white;
    z-index: -1;

    @media only screen and (max-width: 768px) {
      top: 4px;
      left: -1px;
      width: 15px;
      height: 15px;
    }
  }
}

.todayDateSmallLeftSide {
  &:after {
    position: absolute;
    content: "";
    top: -3px;
    left: -6px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $ghost-white;
    z-index: -1;

    @media only screen and (max-width: 768px) {
      top: 4px;
      left: -1px;
      width: 15px;
      height: 15px;
    }
  }
}

.monthBody.small {
  margin-top: 0;
  margin-bottom: 6px;
  padding-left: 6px;
  min-height: 198px;
  height: auto;
  border: none;
  cursor: pointer;
  width: 194px;

  &.leftSide {
    margin-top: 10px;
    width: 225px;
    cursor: auto;
  }

  @media only screen and (max-width: 768px) {
    width: 98px;
    min-height: 150px;
  }

  * {
    border: none;
    font-size: 12px;

    @media only screen and (max-width: 768px) {
      font-size: 9px;
    }
  }

  .dayWeekSection {
    height: 26px;

    &.leftSide {
      gap: 17px;
    }

    @media only screen and (max-width: 768px) {
      gap: 0;
      margin-bottom: 6px;
    }
  }

  .dayWeek {
    padding-top: 5px;
    width: 30px;
    height: 30px;

    @media only screen and (max-width: 768px) {
      width: 14px;
      height: 14px;
    }
  }

  .weekSection {
    &.leftSide {
      gap: 17px;
    }

    @media only screen and (max-width: 768px) {
      gap: 0;
    }
  }

  .day {
    text-align: center;
    width: 30px;
    height: 30px;
    padding: 0;

    @media only screen and (max-width: 768px) {
      width: 14px;
      height: 20px;
    }

    & > p {
      padding: 0;
      color: $dark-blue;

      @media only screen and (max-width: 768px) {
        height: 20px;
      }
    }
  }
}

.circle {
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  z-index: 2;
  margin: 0;

  &.paid {
    background: $green;
  }

  &.owner {
    background: $light-blue;
  }

  @media only screen and (max-width: 768px) {
    width: 3px;
    height: 3px;
  }
}

.circleBlock {
  display: flex;
  z-index: 2;
  gap: 3px;
  justify-content: center;
}

.reservation {
  font-size: 16px;
  padding: 8px 16px;
  margin-top: 10px;
  width: 130px;
  height: 62px;
  display: block;
  background: $white;
  border-top: 1px solid;
  border-bottom: 1px solid;


  @media only screen and (max-width: 768px) {
    padding: 0;
    margin-top: 7px;
    width: calc(100% + 1px);
    height: 44px;
  }

  &.beginning {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-left: 1px solid;
    position: relative;

    & .name {
      font-size: 16px;
      font-weight: 500;
      position: absolute;
      white-space: nowrap;
      z-index: 1;

      @media only screen and (max-width: 768px) {
        font-size: 12px;
        top: 2px;
      }
    }

    .guests {
      top: 20px;
      font-size: 14px;
      font-weight: 400;


      @media only screen and (max-width: 768px) {
        font-size: 10px;
        top: 16px;
      }

      &.upcoming {
        color: #9798A5;
      }

      &.previous {
        color: #9798a585;
      }
    }

  }

  &.ending {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-right: 1px solid;
    width: 60px;

    @media only screen and (max-width: 768px) {
      width: 27px;
    }
  }

  &.bothBeginning {
    position: absolute;
    top: 40px;
    left: 70px;
    border-radius: 16px 0 0 16px;
    border-left: 1px solid;
    border-right: none;

    @media only screen and (max-width: 768px) {
      width: calc(100% - 24px);
      top: 24px;
      left: 25px;
    }
  }

  &.bothEnding {
    position: absolute;
    width: 40px;
    border-right: 1px solid;
    border-radius: 0 16px 16px 0;

    @media only screen and (max-width: 768px) {
      width: 16px;
    }
  }

  &.paid {
    color: $green;
    border-color: $green;
  }

  &.owner {
    color: $light-blue;
    border-color: $light-blue;
  }

  &.paidPrevious {
    color: #aadecd;
    border-color: #aadecd;
  }

  &.ownerPrevious {
    color: $grey;
    border-color: $grey;
  }

  &.paidCurrent {
    background-color: $light-green;
  }

  &.ownerCurrent {
    background-color: $ghost-white;
  }
}

.tooltip {
  font-size: 14px;
  color: $spanish-grey;
  text-align: center;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px -2px $pantone;
  background: $white;
}

.tooltipArrow {
  color: $white;
}

.reservationContent {
  position: absolute;
  left: 60px;

  @media only screen and (max-width: 768px) {
    left: 40px;
  }

  &.noSourceIcon {
    left: 45px;

    @media only screen and (max-width: 768px) {
      left: 15px;
    }
  }
}

.sourceIcon {
  position: absolute;
  top: 15px;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    top: 7px;
    left: 10px;
  }
  svg {
    @media only screen and (max-width: 768px) {
      width: 21px;
    }
  }
}

.paidSvgFill {
  svg {
    path {
      fill: $green;
    }
  }
}

.ownerSvgFill {
  svg {
    path {
      fill: $light-blue;
    }
  }
}

.paidPreviousSvgFill {
  svg {
    path {
      fill: #aadecd;
    }
  }
}

.ownerPreviousSvgFill {
  svg {
    path {
      fill: $grey;
    }
  }
}

.tooltipIcon {
  position: absolute;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    left: 6px;
  }

  button {
    svg {
      @media only screen and (max-width: 768px) {
        width: 14px;
      }
    }
  }

  &.withSourceIcon {
    left: 50px;

    @media only screen and (max-width: 768px) {
      left: 25px;
    }
  }
}
