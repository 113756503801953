@import '../../../../../../assets/styles/variables';
@import '../../../../../../assets/styles/base';
.reservationsHighlightsWrapper {
  margin: 24px 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 32px;
    margin: 0;
    gap: 0;
  }
}

.reservationsHighlightsRow {
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

.calendarHighlight {
  @media only screen and (max-width: 768px) {
    gap: 8px;
    padding: 16px 12px 16px 12px;
    h2 {
      font-size: 18px;
    }
    button {
      padding-right: 0;
      padding-left: 8px;
    }
  }
}