@import '../../../../../../../../assets/styles/variables';
@import '../../../../../../../../assets/styles/base';
.dropDownContainer {
  position: relative;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__value {
      font-size: 16px;
      margin-right: 8px;
      color: $blue-grey;
    }
  }
}

.dropDownListContainer {
  position: absolute;
  z-index: 100;
  left: -10px;
  width: calc(100% + 20px);
  padding-top: 8px;
  animation: fadeIn 0.2s;

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
}

.dropDownList {
  background: $white;
  border: 1px solid $ghost-white;
  border-radius: 8px;
  max-height: 250px;
  overflow: auto;

  &__item {
    padding: 8px 16px;
    list-style: none;
    color: #B1B3C6;
    cursor: pointer;
    width: 70px;

    &:hover {
      color: $dark-grey;
    }
  }
}
