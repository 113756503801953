@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.loginFormContainer {
  text-align: center;
  padding: 60px 110px;
  margin-top: 36px;
  width: 636px;
  background-color: $white;
  border-radius: 24px;
  box-sizing: border-box;


  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
    margin-top: 30px;

    h2 {
      font-size: 18px;
      margin-bottom: 4px;
    }

    p {
      margin-top: 4px;
    }
  }
}

.input {
  margin-top: 24px;
  max-width: 416px;

  @media only screen and (max-width: 768px) {
    max-width: none;
  }
}

.formGroup {
  margin: 15px 0 25px 0;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
}

.rememberme {
  color: $dark-grey;

  @media only screen and (max-width: 768px) {
    margin-left: -8px;
  }
}

.forgotAction {
  text-decoration: none;
  color: $light-blue;
}

.loader {
  color: $white;
}