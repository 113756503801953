@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.card {
  max-width: 992px;
  max-height: 508px;
  background: $white;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
}

.toast {
  position: absolute;
  top: 7%;
}