@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.mobileFooterContainer {
  border-top: 1px solid $ghost-white;
  padding: 3px 16px 4px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $white;
  box-sizing: border-box;
  z-index: 10;
}

.activeButton {
  svg {
    & path {
      fill: $light-blue;
    }

    & rect {
      &~path {
        fill: $white;
      }
      fill: $light-blue;
    }
  }
}
