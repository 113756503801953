@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.occupancyChartsContainer {
  padding: 32px;
  width: 852px;
  //min-width: 830px;
  height: 432px;
  background: $white;
  border-radius: 24px;
  @media only screen and (max-width: 768px) {
    padding: 16px;
    padding-bottom: 32px;
    width: 100%;

  }
}

.occupancyBarContainer {
  max-width: 1292px;
  width: 100%;
  height: 604px;
  //height: 60%;
}

.occupancyChartsHeader {
  margin: 0 0 30px 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 768px) {
    margin: 0 0 24px 0;
    justify-content: space-between;
  }
}

.periodBlock {
  display: flex;
  gap: 12px;
  @media only screen and (max-width: 768px) {
    gap: 6px;
  }
}

.legendRow {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  width: 317px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: auto;
  }
}

.switcher {
  margin-left: 30px;
  display: flex;
  gap: 10px;
  @media only screen and (max-width: 768px) {
    align-self: flex-end;
    position: absolute;
    top: -5px;
  }
}

.button {
  border-radius: 8px;
}

.activeButton {
  background: $ghost-white;

  & svg path {
    fill: $dark-blue;
  }
}

.standardDropdown {
  @media only screen and (max-width: 768px) {
    width: 78px;
  }
}

.centerSelfAlign {
  align-self: center;
}