@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.divider {
  margin-top: 15px;
}

.netIncomeHighlight {
  margin-top: 15px;
  padding-left: 6px;
}
