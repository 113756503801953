@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.buttonGroup {
  border-radius: 16px;
  border: 1px solid $ghost-white;

  @media only screen and (max-width: 768px) {
    border: none;
    border-radius: 0;
  }
}

.button {
  font-size: 16px;
  color: $light-blue;
  background: $white;
  border: 1px solid $ghost-white;
  height: 40px;
  width: 150px;

  @media only screen and (max-width: 768px) {
    border: none !important;
    border-radius: 0;
    height: 36px;
    width: 100px;
    font-size: 14px;
    font-weight: 400;
  }
}

.activeButton {
  font-size: 16px;
  color: $light-blue;
  background: $ghost-white;
  border: 1px solid $ghost-white;
  height: 40px;
  width: 150px;

  @media only screen and (max-width: 768px) {
    background: $white;
    border-radius: 0;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    background-color: white !important;
    border-bottom: 2px $light-blue solid;
    height: 36px;
    width: 100px;
    font-size: 14px;
  }
}
