@import '../../../../../assets/styles/variables';
@import '../../../../../assets/styles/base';
.leaveUsAMessageContainer {
  @media only screen and (max-width: 768px) {
    padding: 16px;
    padding-top: 32px;

    h2 {
      font-size: 16px;
    }

    p {
      font-size: 14px;
    }
  }
}