$white:       #ffffff;
$ghost-white: #F5F5FC;
$grey:        #B4B7CF;
$light-grey:  #D3D4DE;
$dark-grey:   #9798A5;
$spanish-grey:#93949e;
$pantone:     #1b212c1f;
$light-green: #d9f8f0;
$green:       #008C74;
$blue-grey:   #424581;
$light-blue:  #424581;
$dark-blue:   #02094F;
$light-red:   #ffe1e7;
$red:         #FF385C;
$yellow:      #EDC255;

