@import '../../../assets/styles/variables';
@import '../../../assets/styles/base';
.listingsWrapper {
  align-items: center;
}

.listingsTableContainer {
  padding: 32px;
  width: 1296px;
  min-height: 200px;
  height: auto;
  background: $white;
  border-radius: 24px;
  overflow-y: auto;

  h4 {
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
}

.tableHead {
  background: $ghost-white;

  & th {
    font-size: 16px;
    color: $light-blue;
    border: 0;
    padding: 8px 16px;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    & th {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.tableBody {
  & tr {
    border-bottom: 1px solid $ghost-white;

    & td {
      padding: 32px 0 24px 16px;
    }
  }

  @media only screen and (max-width: 768px) {
    & tr {
      & td {
        padding: 28px 0 20px 16px;
      }
    }
  }
}

.tableRow {
  & td {
    font-size: 16px;
    color: $dark-grey;
    padding: 10px;
    border: 0;
  }
}

.channelCell {
  display: flex;
  align-items: center;
  gap: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;

    svg {
      width: 22px;
    }
  }
}

.listingLink {
  font-size: 16px;
  font-weight: 500;
  color: $light-blue;
  text-decoration-line: underline;
  margin: 0;
  cursor: pointer;

  &.inactive {
    color: $dark-grey;
    text-decoration: none;
    cursor: default;
  }

  & svg {
    margin-left: 5px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
}

.activeBadge,
.inactiveBadge {
  font-size: 14px;
  text-align: center;
  padding-top: 8px;
  width: 120px;
  height: 36px;
  display: inline-block;
  border-radius: 8px;

  & span {
    margin-left: 5px;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
  }

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    width: 83px;
    height: 30px;
    padding-top: 7px;

    & span {
      width: 7px;
      height: 7px;
    }
  }
}

.activeBadge {
  color: $green;
  background: $light-green;

  & span {
    background: $green;
  }
}

.inactiveBadge {
  color: $red;
  background: $light-red;
  & span {
    background-color: $red;
  }
}
